import {authApi, LoginParamsType, personalAreaAPI} from "./axios.api";
import {getToken, setToken} from "../utils/utils";
import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {AppThunk} from "./store";
import {appActions} from "./appReducer";
import {handleServerAppError, handleServerNetworkError} from "../utils/error-utils";
import {Bounce, toast} from "react-toastify";


type InitialStateType = {
    isLoggedIn: boolean
}


const slice = createSlice({
    name: "auth",
    initialState: {
        isLoggedIn: Boolean(getToken()) || false,
    },
    reducers: {
        setIsLoggedIn: (state, action: PayloadAction<{ isLoggedIn: boolean }>) => {
            state.isLoggedIn = action.payload.isLoggedIn;
        },
    },
});
export const authReducer = slice.reducer;
export const authActions = slice.actions;


export const loginTC = (key: string): AppThunk =>
    (dispatch) => {
        dispatch(appActions.setAppStatus({status: "loading"}));
        personalAreaAPI.auth(key).then((res) => {
            dispatch(authActions.setIsLoggedIn({isLoggedIn: true}));
            dispatch(appActions.setAppStatus({status: "succeeded"}));
            setToken(key)
        })
            .catch((error) => {
                toast.error(error.response.data.detail, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                    transition: Bounce,
                });

                // handleServerNetworkError(error, dispatch);
            });
    };

export const setIsLoggedInAC = (value: boolean) =>
    ({type: 'login/SET-IS-LOGGED-IN', value} as const)

export type ActionsType = ReturnType<typeof setIsLoggedInAC>


