import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {AppThunk} from "./store";
import {personalAreaAPI} from "./axios.api";
import {appActions} from "./appReducer";
import {handleServerNetworkError} from "../utils/error-utils";
import {Bounce, toast} from "react-toastify";
import {successToastify} from "../utils/utils";

export type UserStateType = {
    name: string
    tg_id: string
    id: number
}
const initialState: UserStateType[] = [];

const slice = createSlice({
    name: "chats",
    initialState,
    reducers: {
        removeChat: (state, action: PayloadAction<{ chat_id: number }>) => {
            const index = state.findIndex((u) => u.id === action.payload.chat_id);
            if (index !== -1) state.splice(index, 1);
        },
        addChat: (state, action: PayloadAction<{ user: UserStateType }>) => {
            state.push(action.payload.user);
        },
        setChats: (state, action: PayloadAction<{ users: Array<UserStateType> }>) => {
            return action.payload.users;
        },
    },

});

export const chatsReducers = slice.reducer;
export const chatsActions = slice.actions;

// thunks

export const fetchChatsTC = (): AppThunk => {
    return (dispatch) => {
        dispatch(appActions.setAppStatus({status: "loading"}));
        personalAreaAPI.getChats().then((res) => {
            dispatch(chatsActions.setChats({users: res.data}));
            dispatch(appActions.setAppStatus({status: "succeeded"}));
        }).catch((error) => {
            handleServerNetworkError(error, dispatch);
        });

    }
}

export type AddChatDataType = {
    name: string
    tg_id: string
}
export const addChatTC = (data: AddChatDataType): AppThunk => {
    return (dispatch) => {
        dispatch(appActions.setAppStatus({status: "loading"}));
        personalAreaAPI.addChat(data).then((res) => {
            dispatch(chatsActions.addChat({user: res.data}));
            dispatch(appActions.setAppStatus({status: "succeeded"}));
            successToastify()
        }).catch((error) => {
            handleServerNetworkError(error, dispatch);
        });

    }
}


export const removeChatTC = (chat_id: number): AppThunk => {
    return (dispatch) => {
        dispatch(appActions.setAppStatus({status: "loading"}));
        personalAreaAPI.deleteChat(chat_id).then(() => {
            dispatch(chatsActions.removeChat({chat_id}));
            dispatch(appActions.setAppStatus({status: "succeeded"}));
            successToastify()
        }).catch((error) => {
            handleServerNetworkError(error, dispatch);
        });
    }
};

