import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {AppThunk} from "./store";
import {personalAreaAPI} from "./axios.api";
import {appActions} from "./appReducer";
import {handleServerNetworkError} from "../utils/error-utils";

export type BalanesType = {
    amount: number
    coin: string
}

export type MonitorWalletsDataType = {
    offset: number
    limit: number
}
export type MonitorWalletsStateType = {
    address: string
    index?: number
    wallet_id?: number
    chain: string
    currency: string
    balance: Array<BalanesType>
    id: number
    is_active?: boolean
    status?: boolean
}
type AddWalletDataTYpe = {
    address: string
    currency: string
    balance: Array<BalanesType>
    // root_address: {
    //     address: string
    // }
}
const initialState: MonitorWalletsStateType[] = [];

const slice = createSlice({
    name: "monitorWallet",
    initialState,
    reducers: {
        getMonitorWallets: (state, action: PayloadAction<{
            wallets: MonitorWalletsStateType[]
        }>) => {
            state = action.payload.wallets;
            return state
        },
        addWallet: (state, action: PayloadAction<MonitorWalletsStateType>) => {
            state.push(action.payload)
        },
    }
});

export const MonitorWalletReducers = slice.reducer;
export const MonitorWalletActions = slice.actions;

// thunks

export const fetchMonitorWalletsTC = (data: MonitorWalletsDataType): AppThunk => {
    return (dispatch) => {
        dispatch(appActions.setAppStatus({status: "loading"}));
        personalAreaAPI.getMonitorWallets(data).then((res) => {
            dispatch(MonitorWalletActions.getMonitorWallets({wallets: res.data}));

            dispatch(appActions.setAppStatus({status: "succeeded"}));
        }).catch((error) => {
            handleServerNetworkError(error, dispatch);
        });

    }
}

export const addWalletTC = (chain: string, mnemonic: string): AppThunk<Promise<AddWalletDataTYpe | void>> =>
    async (dispatch) => {
        dispatch(appActions.setAppStatus({status: "loading"}));
        try {
            personalAreaAPI.setHdWallet(chain, mnemonic).then((res) => {
                dispatch(fetchMonitorWalletsTC({
                    offset: 0,
                    limit: 200
                }))
            })
            dispatch(appActions.setAppStatus({status: "succeeded"}));
        } catch
            (err) {
            // handleServerNetworkError(err, dispatch);
        }
    }
;
export const removeWalletTC = (id: number): AppThunk<Promise<AddWalletDataTYpe | void>> =>
    async (dispatch) => {
        dispatch(appActions.setAppStatus({status: "loading"}));
        try {
            personalAreaAPI.removeHdWallet(id).then((res) => {
                dispatch(fetchMonitorWalletsTC({
                    offset: 0,
                    limit: 200
                }))
            })
            dispatch(appActions.setAppStatus({status: "succeeded"}));
        } catch
            (err) {
            // handleServerNetworkError(err, dispatch);
        }
    }
;


