import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {AppThunk} from "./store";
import {personalAreaAPI} from "./axios.api";
import {appActions} from "./appReducer";
import {handleServerNetworkError} from "../utils/error-utils";
import {successToastify} from "../utils/utils";

export type UserWalletsStateType = {
    name: string
    tg_id: string
    btc: string
    eth: string
    bsc: string
    bnb: string
    id?: number
    mode?: boolean
}
export type AddUsersWalletsDataType = {
    name: string,
    tg_id: string,
    btc: string,
    eth: string,
    bsc: string,
    bnb: string
    mode?: boolean
}
const initialState: UserWalletsStateType[] = [];

const slice = createSlice({
    name: "users",
    initialState,
    reducers: {
        removeUser: (state, action: PayloadAction<{ userId: number }>) => {
            const index = state.findIndex((u) => u.id === action.payload.userId);
            if (index !== -1) state.splice(index, 1);
        },
        addUser: (state, action: PayloadAction<{
            user: AddUsersWalletsDataType
        }>) => {
            state.push(action.payload.user);
        },
        setUsersWallets: (state, action: PayloadAction<{
            users: Array<UserWalletsStateType>
        }>) => {
            return action.payload.users;
        },
    },

});

export const usersWalletsReducers = slice.reducer;
export const usersWalletsActions = slice.actions;

// thunks

export const fetchUsersWalletsTC = (): AppThunk => {
    return (dispatch) => {
        dispatch(appActions.setAppStatus({status: "loading"}));
        personalAreaAPI.getUsersWallets().then((res) => {
            dispatch(usersWalletsActions.setUsersWallets({users: res.data}));
            dispatch(appActions.setAppStatus({status: "succeeded"}));
        }).catch((error) => {
            handleServerNetworkError(error, dispatch);
        });

    }
}
// export type AddUserDataType = {
//     name: string
//     tg_id: string
// }
export const addUserWalletsTC = (data: AddUsersWalletsDataType): AppThunk => {
    return (dispatch) => {
        dispatch(appActions.setAppStatus({status: "loading"}));
        dispatch(usersWalletsActions.addUser({user: data}));
        dispatch(appActions.setAppStatus({status: "succeeded"}));
    }
}
export const setUserWalletsTC = (data: AddUsersWalletsDataType): AppThunk => {
    return (dispatch) => {
        dispatch(appActions.setAppStatus({status: "loading"}));
        personalAreaAPI.addUsersWallet(data).then((res) => {
            dispatch(fetchUsersWalletsTC());
            dispatch(appActions.setAppStatus({status: "succeeded"}));
            successToastify()
        }).catch((error) => {
            handleServerNetworkError(error, dispatch);
        });

    }
}


export const removeUsersWalletsTC =
    (userId: number): AppThunk =>
        (dispatch) => {
            dispatch(appActions.setAppStatus({status: "loading"}));
            personalAreaAPI.deleteUsersWallet(userId).then(() => {
                dispatch(usersWalletsActions.removeUser({userId}));
                dispatch(appActions.setAppStatus({status: "succeeded"}));
                successToastify()
            }).catch((error) => {
                handleServerNetworkError(error, dispatch);
            });
        };
