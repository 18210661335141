import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {AppThunk} from "./store";
import {personalAreaAPI} from "./axios.api";
import {appActions} from "./appReducer";
import {handleServerNetworkError} from "../utils/error-utils";
import {successToastify} from "../utils/utils";

export type ConclusionStateType = {
    chain: string
    low: number
    address: string
    id?: number
}
const initialState: ConclusionStateType[] = [];

const slice = createSlice({
    name: "conclusion",
    initialState,
    reducers: {
        getGas: (state, action: PayloadAction<{ coins: Array<ConclusionStateType> }>) => {
            return action.payload.coins;
        },
        setGas: (state, action: PayloadAction<{ gas: ConclusionStateType, id: number }>) => {
            // const tasks = state[action.payload.coins];
            const index = state.findIndex((t) => t.id === action.payload.id);
            if (index !== -1) {
                state[index] = {...state[index], ...action.payload.gas};
            }
        },
    }

});

export const conclusionReducers = slice.reducer;
export const conclusionActions = slice.actions;

// thunks

export const fetchGasTC = (): AppThunk => {
    return (dispatch) => {
        dispatch(appActions.setAppStatus({status: "loading"}));
        personalAreaAPI.getGas().then((res) => {
            dispatch(conclusionActions.getGas({coins: res.data}));
            dispatch(appActions.setAppStatus({status: "succeeded"}));
        }).catch((error) => {
            handleServerNetworkError(error, dispatch);
        });

    }
}
export const setGasTC = (gas: ConclusionStateType, id: number): AppThunk => {
    return (dispatch) => {
        dispatch(appActions.setAppStatus({status: "loading"}));
        personalAreaAPI.setGas(gas).then((res) => {
            dispatch(conclusionActions.setGas({gas, id}));
            dispatch(appActions.setAppStatus({status: "succeeded"}));
            successToastify()
        }).catch((error) => {
            handleServerNetworkError(error, dispatch);
        });

    }
}
