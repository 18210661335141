import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {AppThunk} from "./store";
import {personalAreaAPI} from "./axios.api";
import {appActions} from "./appReducer";
import {handleServerAppError, handleServerNetworkError} from "../utils/error-utils";
import {successToastify} from "../utils/utils";
import {toast} from "react-toastify";
import error = toast.error;

export type StatisticsItemsType = {
    number: number
    date?: string
    name: string
    BTC: string | null
    ETH: string | null
    USDT: string | null
    BSC_USD: string | null
    BSC_BNB: string
    Beacon_BNB: string
    eq: string
}
export type StatisticsDataType = {
    data: StatisticsItemsType[]
    data_managers: StatisticsItemsType[]
    total_pages: number
    previous_month_link: string
}

const initialState: StatisticsDataType = {
    data: [],
    data_managers: [],
    total_pages: 0,
    previous_month_link: ''
};

const slice = createSlice({
    name: "statistics",
    initialState,
    reducers: {
        getStatisticsItems: (state, action: PayloadAction<{ data: StatisticsDataType }>) => {
            return action.payload.data;
        }
    }

});

export const staisticsReducers = slice.reducer;
export const staisticsActions = slice.actions;

// thunks

export const fetchStatisticsTC = (page: number): AppThunk => {
    return (dispatch) => {
        dispatch(appActions.setAppStatus({status: "loading"}));
        personalAreaAPI.fetchStatistics(page).then((res) => {
            dispatch(staisticsActions.getStatisticsItems({data: res.data}));
            dispatch(appActions.setAppStatus({status: "succeeded"}));
            successToastify()
        }).catch((error) => {
            handleServerNetworkError(error, dispatch);
            console.log(error)
        })
            .catch((err) => {
                handleServerAppError(err, dispatch)
            });

    }
}

