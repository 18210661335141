import {AnyAction, combineReducers} from "redux";
import {ThunkAction, ThunkDispatch} from "redux-thunk";
import {appReducer} from "./appReducer";
import {configureStore} from "@reduxjs/toolkit";
import {usersReducers} from "./UsersReducer";
import {chatsReducers} from "./Chatsreducers";
import {conclusionReducers} from "./conclusionReducer";
import {autoLimitReducers} from "./autoLimitReducer";
import {HdWalletReducers} from "./hdWalletReducer";
import {MonitorWalletReducers} from "./monitorWalletsReducer";
import {usersWalletsReducers} from "./usersWalletsReducer";
import {authReducer} from "./authReducer";
import {staisticsReducers} from "./statisticsReducer";
import {screensReducers} from "./ScreenBotReducer";
import {videoReducers} from "./VideoBotReducer";


export const rootReducer = combineReducers({
    auth: authReducer,
    app: appReducer,
    users: usersReducers,
    chats: chatsReducers,
    conclusion: conclusionReducers,
    autoLimit: autoLimitReducers,
    hdWallet: HdWalletReducers,
    monitorWallets: MonitorWalletReducers,
    usersWallets: usersWalletsReducers,
    statistics: staisticsReducers,
    screen: screensReducers,
    videos: videoReducers
})
export type AppRootStateType = ReturnType<typeof rootReducer>

export const store = configureStore({
    reducer: rootReducer
})

export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, AppRootStateType, unknown, AnyAction>;

export type AppDispatch = ThunkDispatch<AppRootStateType, unknown, AnyAction>;

// @ts-ignore
window.store = store