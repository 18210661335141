import style from './Page500.module.scss'
import Page404 from "../page404/Page404";

const Page500 = () => {
    return(
        <div className={style.Wrapper}>

        </div>
    )
}
export default Page500;