import {Dispatch} from "redux";
import {appActions} from "../state/appReducer";
import {Bounce, toast} from "react-toastify";
import {removeToken} from "./utils";
import {AxiosError} from "axios";

export const handleServerAppError = <D>(data: ResponseType<D>, dispatch: Dispatch) => {
    // if (data.messages.length) {
    //     dispatch(appActions.setAppError({error: data.messages[0]}));
    // } else {
    //     dispatch(appActions.setAppError({error: "Some error occurred"}));
    // }
    // dispatch(appActions.setAppStatus({status: "failed"}));
    console.log('sdfsd')
    toast.error("Server Error", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        transition: Bounce,
    });
};

export const handleServerNetworkError = (error:AxiosError, dispatch: Dispatch) => {
    toast.error(error.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        transition: Bounce,
    });
    if (error.response?.status === 401) {
        removeToken()
    }
    if (error.response?.status === 403) {
        removeToken()
    }

    dispatch(appActions.setAppError({error: error.message ? error.message : "Some error occurred"}));
    dispatch(appActions.setAppStatus({status: "failed"}));
};

export type ResponseType<D = {}> = {
    resultCode: number;
    messages: Array<string>;
    data: D;
};