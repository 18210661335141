import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {AppThunk} from "./store";
import {personalAreaAPI} from "./axios.api";
import {appActions} from "./appReducer";
import {handleServerNetworkError} from "../utils/error-utils";
import {Bounce, toast} from "react-toastify";
import {successToastify} from "../utils/utils";
import {AxiosError} from "axios";

export type HdWalletStateType = {
    wallet_name: string
    chain: string
    description: string
    exchange: true
}
const initialState: HdWalletStateType[] = [];

const slice = createSlice({
    name: "hdWallet",
    initialState,
    reducers: {
        setHdWallet: (state, action: PayloadAction<HdWalletStateType[]>) => {
           state = action.payload;
           console.log(state)
           return state
        },
    }

});

export const HdWalletReducers = slice.reducer;
export const HdWalletActions = slice.actions;

// thunks

export const fetchHdWalletTC = (chain: string, mnemonic: string): AppThunk => {
    return (dispatch) => {
        dispatch(appActions.setAppStatus({status: "loading"}));
        personalAreaAPI.setHdWallet(chain, mnemonic).then((res) => {
            dispatch(HdWalletActions.setHdWallet(res.data));
            // dispatch(HdWalletActions.addHdWallet());
            dispatch(appActions.setAppStatus({status: "succeeded"}));
            // successToastify()
        }).catch((error) => {
            handleServerNetworkError(error, dispatch);
        });

    }
}


