import {Bounce, toast} from "react-toastify";

export const getToken = () => {
    return localStorage.getItem('token')
}
export const setToken = (token: string) => {
    return localStorage.setItem('token', token)
}
export const removeToken = () => {
    localStorage.removeItem('token')
    window.location.href = '/login';
}
export const successToastify = () => {
    toast.success('success', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        transition: Bounce,
    });
}