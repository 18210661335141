import {Route, Routes} from "react-router-dom";

import React from "react";
import routes from "../../routes/routes";
import Page404 from "../../pages/page404/Page404";

const MainContent = () => {
    return (
        <>
            <Routes>
                {
                    routes.map((route, idx) => {
                            return (
                                <Route
                                    index={route.index}
                                    key={idx}
                                    path={route.path}
                                    element={<route.element/>}
                                />
                            )
                        }
                    )
                }
                <Route path="*" element={<Page404/>}/>
            </Routes>
        </>
    )
}
export default MainContent