import React, {FC, memo, useEffect, useState} from "react";
import {NavLink} from "react-router-dom";
import style from './Navbar.module.scss';
import MenuIcon from '@mui/icons-material/Menu';
import {Button} from "@mui/material";
import {ReactComponent as Logo} from '../../assets/images/Logo.svg'
import CloseIcon from '@mui/icons-material/Close';
import {slide as Menu} from 'react-burger-menu'

export const NavbarComponent = memo(function NavbarComponent() {
    const [openMenu, setOpenMenu] = useState(false)

    const toggleMenu = () => {
        setOpenMenu(!openMenu)
    }
    const time = 120

    useEffect(() => {

    }, [])

    useEffect(() => {
        let timer1 = setInterval(() => {
        }, time * 1000)
        return () => {
            clearTimeout(timer1);
        };
    }, [time]);

    return (
        <header>
            <div className={style.Container}>
                <div className={style.LogoBlock}>
                    <Logo/>
                    <p>
                        Vmodule
                    </p>
                </div>
                <nav className={style.NavDesk}>
                    <NavCustom/>
                </nav>
                <Button className={style.BurgerBtn} onClick={toggleMenu}>
                    {
                        openMenu ? <CloseIcon/> : <MenuIcon/>
                    }
                </Button>
            </div>
            <div className={style.MenuMobile}>
                <Menu isOpen={openMenu}
                      onOpen={toggleMenu}
                      onClose={toggleMenu}
                      right={true}
                      className={style.BurgerMenu}
                      outerContainerId={"outer-container"}>
                    <NavCustom closeMenu={toggleMenu}/>
                </Menu>
            </div>
        </header>
    )
})
type NavCustomType = {
    closeMenu?:() => void
}
export const NavCustom:FC<NavCustomType> = ({closeMenu}) => {
    return (
        <>
            <NavLink to={'users'}
                     onClick={closeMenu}
                     className={({isActive}) => (isActive ? style.ActiveLink : style.InActiveLink)}>
                Пользователи</NavLink>
            <NavLink to={'wallets'}
                     onClick={closeMenu}
                     className={({isActive}) => (isActive ? style.ActiveLink : style.InActiveLink)}>
                Кошельки</NavLink>
            <NavLink to={'withdraw'}
                     onClick={closeMenu}
                     className={({isActive}) => (isActive ? style.ActiveLink : style.InActiveLink)}>
                Вывод</NavLink>
            <NavLink to={'statistics'}
                     onClick={closeMenu}
                     className={({isActive}) => (isActive ? style.ActiveLink : style.InActiveLink)}>
                Статистика</NavLink>
            <NavLink to={'screenBot'}
                     onClick={closeMenu}
                     className={({isActive}) => (isActive ? style.ActiveLink : style.InActiveLink)}>
                Screenshot bot</NavLink>
            <NavLink to={'videoBot'} onClick={closeMenu}
                     className={({isActive}) => (isActive ? style.ActiveLink : style.InActiveLink)}>
                Video Bot</NavLink>
        </>
    )
}
