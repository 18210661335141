import style from './Login.module.scss';
import {Form, Formik} from "formik";
import * as Yup from "yup";
import React, {useEffect} from "react";
import {TextField} from "@mui/material";
import {useSelector} from "react-redux";
import {useNavigate} from "react-router";
import {AppRootStateType} from "../../state/store";
import {RequestStatusType} from "../../state/appReducer";
import {loginTC} from "../../state/authReducer";
import {useAppDispatch} from "../../hooks/useAppDispatch";
import {selectIsLoggedIn} from './auth.selectors';


const FORM_VALIDATION = Yup.object().shape({
    key: Yup.string().required('Required'),
})

export const LoginPage = () => {
    const dispatch = useAppDispatch();
    const status = useSelector<AppRootStateType, RequestStatusType>(state => state.app.status);
    const isLoggedIn = useSelector(selectIsLoggedIn);

    let navigate = useNavigate();

    useEffect(() => {
        if (isLoggedIn) {
            return navigate("/users");
        }
    }, [isLoggedIn]);

    return (

        <div className={style.Wrapper}>

            <Formik initialValues={{
                key: '',
            }}
                    validationSchema={FORM_VALIDATION}
                    enableReinitialize={true}
                    onSubmit={(values, action) => {
                        dispatch(loginTC(values.key))
                    }}>
                {({
                      errors,
                      getFieldProps,
                      touched,
                      dirty,
                      isValid,
                      handleSubmit,
                  }) => {

                    const loginError = (errors.key && touched.key);

                    return <Form onSubmit={handleSubmit} className={style.Form}>
                        <h1>Вход</h1>
                        {
                            status === 'failed'
                                ? <p className={style.ErrorDesc}>Вы ввели неверный токен</p>
                                : <p>
                                    Введите токен
                                </p>
                        }

                        <div className={style.FieldWr}>
                            <TextField
                                {...getFieldProps('key')}
                                type="text"
                                autoFocus
                                className={`${style.Input} ${(loginError) && style.InputError}`}
                                placeholder={''}
                            />
                            {loginError ?
                                <div className={style.Error}>{errors.key}</div> : null}
                        </div>
                        <button
                            className={`${style.BtnSubmit} ${(!isValid || !dirty) && style.BtnSubmitDisabled}`}
                            disabled={!isValid || !dirty}
                            type={'submit'}
                        >
                            Войти
                        </button>
                    </Form>
                }}
            </Formik>
        </div>
    )
}