import {lazy} from 'react';
import {RoutesType} from "../state/types";


const Users = lazy(() => import('../pages/users/Users'));
const Сonclusion = lazy(() => import('../pages/conclusion/Сonclusion'));
const Wallets = lazy(() => import('../pages/wallets/Wallets'));
const Statistics = lazy(() => import('../pages/statistics/Statistics'));
const VideoBots = lazy(() => import('../pages/videoBot/VideoBot'));
const ScreenBots = lazy(() => import('../pages/screenBots/ScreenBots'));


const routes: RoutesType[] = [
    {path: '/users', element: Users, index: true},
    {path: '/wallets', element: Wallets, index: false},
    {path: '/withdraw', element: Сonclusion, index: false},
    {path: '/statistics', element: Statistics, index: false},
    {path: '/screenBot', element: ScreenBots, index: false},
    {path: '/videoBot', element: VideoBots, index: false},
]

export default routes
