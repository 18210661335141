import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {AppThunk} from "./store";
import {personalAreaAPI} from "./axios.api";
import {appActions} from "./appReducer";
import {handleServerNetworkError} from "../utils/error-utils";
import {successToastify} from "../utils/utils";
import {AxiosError} from "axios";

export type VideoStateType = {
    id: number
    name: string
    start_param: string
    tg_link: string
    en_link: string
    de_link: string
    it_link: string
    da_link: string
    es_link: string
    sk_link: string
    nl_link: string
}
export type VideoLinkDataType = {
    id: number
    en_link?: string
    de_link?: string
    it_link?: string
    da_link?: string
    es_link?: string
    sk_link?: string
    nl_link?: string
}
export type AddVideoLinkDataType = {
    name: string
    en_link: string
    de_link: string
    it_link: string
    da_link: string
    es_link: string
    sk_link: string
    nl_link: string
    start_param: string
}
export type LinksType =
    'name'
    | 'en_link'
    | 'de_link'
    | 'it_link'
    | 'da_link'
    | 'es_link'
    | 'sk_link'
    | 'nl_link'
    | 'start_param'

export enum LinksEnum {
    en_link = 'en_link',
    de_link = 'de_link',
    it_link = 'it_link',
    da_link = 'da_link',
    es_link = 'es_link',
    sk_link = 'sk_link',
    nl_link = 'nl_link',
}

const initialState: VideoStateType[] = [];

const slice = createSlice({
    name: "video",
    initialState,
    reducers: {
        addVideo: (state, action: PayloadAction<VideoStateType>) => {
            state.push(action.payload);
        },
        setVideos: (state, action: PayloadAction<VideoStateType[]>) => {
            return action.payload;
        },
    },

});

export const videoReducers = slice.reducer;
export const videoActions = slice.actions;

// thunks

export const fetchVideosTC = (): AppThunk => {
    return (dispatch) => {
        dispatch(appActions.setAppStatus({status: "loading"}));
        personalAreaAPI.getVideoBots().then((res) => {
            dispatch(videoActions.setVideos(res.data));
            dispatch(appActions.setAppStatus({status: "succeeded"}));
        }).catch((error) => {
            console.log(error)
            handleServerNetworkError(error, dispatch);
        });

    }
}

export const addVideoTC = (data: AddVideoLinkDataType): AppThunk<Promise<AddVideoLinkDataType | void>> =>
    async (dispatch) => {
        dispatch(appActions.setAppStatus({status: "loading"}));
        try {
            const res = await personalAreaAPI.addVideoBot(data);
            // dispatch(videoActions.addVideo(res.data));
            dispatch(fetchVideosTC())
            successToastify()
            dispatch(appActions.setAppStatus({status: "succeeded"}));
            return res.data
        } catch (err) {
            if (err instanceof AxiosError)
                handleServerNetworkError(err, dispatch);
        }
}

export const ChangeVideoBotTC = (data: VideoLinkDataType): AppThunk<Promise<any>> =>
    async (dispatch) => {
        dispatch(appActions.setAppStatus({status: "loading"}));
        try {
            const res = await personalAreaAPI.changeVideoLink(data);
            // dispatch(videoActions.addVideo(res.data));
            dispatch(fetchVideosTC())
            successToastify()
            dispatch(appActions.setAppStatus({status: "succeeded"}));
            console.log(res)
            return res
        } catch (err) {
            if (err instanceof AxiosError)
                handleServerNetworkError(err, dispatch);
        }
    }

