import style from './Page404.module.scss'
import {Link, Navigate} from "react-router-dom";

const Page404 = () => {
    return (
        <div className={style.Wrapper}>
            <h1>404</h1>
            <h2>Page Not Found</h2>
            <Link to={'/login'}>login</Link>
        </div>
    )
}
export default Page404;