import axios, {AxiosResponse} from "axios";
import {ResponseType} from "./types";
import {AddUserDataType} from "./UsersReducer";
import {ConclusionStateType} from "./conclusionReducer";
import {AutoLimitStateType} from "./autoLimitReducer";
import {MonitorWalletsDataType} from "./monitorWalletsReducer";
import {AddUsersWalletsDataType} from "./usersWalletsReducer";
import {getToken} from "../utils/utils";
import {AddVideoLinkDataType, VideoLinkDataType} from "./VideoBotReducer";

const instance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
})
const instance2 = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    paramsSerializer: {
        encode: (param) => encodeURIComponent(param).replace(/\s/g, '%20'),
    },
})
export type LoginParamsType = {
    login: string
    password: string
}

export const personalAreaAPI = {
    auth(key: string) {
        return instance.get<any>(`check_key/`, {
            headers: {'X-API-KEY': `${key || getToken()}`},
        });
    },
    getUsers() {
        return instance.get<any>(`notify_personal/`, {
            headers: {'X-API-KEY': `${getToken()}`},
        });
    },
    deleteUser(userId: number) {
        return instance.delete<any>(`notify_personal/${userId}`, {
            headers: {'X-API-KEY': `${getToken()}`},
        });
    },
    addUser(data: AddUserDataType) {
        return instance.post<any>(`notify_personal/`, {
            name: data.name,
            tg_id: data.tg_id,
        }, {
            headers: {'X-API-KEY': `${getToken()}`},
        });
    },
    getVideoBots() {
        return instance.get<any>(`videos_links/`, {
            headers: {'X-API-KEY': `${getToken()}`},
        });
    },
    addVideoBot(data: AddVideoLinkDataType) {
        return instance.post<any>(`videos_links`, data, {
            headers: {'X-API-KEY': `${getToken()}`},
        });
    },
    changeVideoLink(data: VideoLinkDataType):Promise<AxiosResponse> {
        return instance.post<any>(`update_videos_links/`, data, {
            headers: {'X-API-KEY': `${getToken()}`},
        });
    },

    getScreenBots() {
        return instance.get<any>(`whitelist_screenshots/`, {
            headers: {'X-API-KEY': `${getToken()}`},
        });
    },
    addScreenBots(data: AddUserDataType) {
        return instance.post<any>(`whitelist_screenshots/`, {
            name: data.name,
            tg_id: data.tg_id,
        }, {
            headers: {'X-API-KEY': `${getToken()}`},
        });
    },
    deleteScreenBots(userId: number) {
        return instance.delete<any>(`whitelist_screenshots/${userId}`, {
            headers: {'X-API-KEY': `${getToken()}`},
        });
    },
    getChats() {
        return instance.get<any>(`notify_chat/`, {
            headers: {'X-API-KEY': `${getToken()}`},
        });
    },
    deleteChat(chat_id: number) {
        const promise = instance.delete<any>(`notify_chat/${chat_id}`, {
            headers: {'X-API-KEY': `${getToken()}`}
        });
        return promise
    },
    addChat(data: AddUserDataType) {
        return instance.post<any>(`notify_chat/`, {
            name: data.name,
            tg_id: data.tg_id
        }, {
            headers: {'X-API-KEY': `${getToken()}`}
        });
    },
    getGas() {
        return instance.get<any>(`auto/`, {
            headers: {'X-API-KEY': `${getToken()}`}
        });
    },
    setGas(data: ConclusionStateType) {
        return instance.post<any>(`auto/`, {
            chain: data.chain,
            low: data.low,
            address: data.address

        }, {
            headers: {'X-API-KEY': `${getToken()}`}
        })
    },
    getAutoLimits() {
        return instance.get<any>(`auto_limits/`, {
            headers: {'X-API-KEY': `${getToken()}`}
        });
    },
    setAutoLimit(data: AutoLimitStateType) {
        return instance.post<any>(`auto_limits/`, {
            chain: data.chain,
            coin: data.coin,
            min_amount: data.min_amount

        }, {
            headers: {'X-API-KEY': `${getToken()}`}
        })
    },
    setHdWallet(chain: string, mnemonic: string) {
        return instance2.post<any>(`hd_wallet/`, {
                wallet_name: '',
                chain: chain,
                description: '',
                exchange: true,
                mnemonic: mnemonic
            },
            {
                headers: {'X-API-KEY': `${getToken()}`},
            }
        )
    },
    removeHdWallet(id: number) {
        return instance2.delete<any>(`hd_wallet/${id}`,
            {
                headers: {'X-API-KEY': `${getToken()}`},
            }
        )
    },
    getMonitorWallets(data: MonitorWalletsDataType) {
        return instance.get<any>(`all_addresses/`,
            {
                params: data,
                headers: {'X-API-KEY': `${getToken()}`},
            }
        )
    },
    getUsersWallets() {
        return instance.get<any>(`users/`, {
            headers: {'X-API-KEY': `${getToken()}`},
        });
    },
    addUsersWallet(data: AddUsersWalletsDataType) {
        return instance.post<any>(`users/`, data, {
            headers: {'X-API-KEY': `${getToken()}`}
        });
    },
    deleteUsersWallet(userId: number) {
        return instance.delete<any>(`users/${userId}`, {
            headers: {'X-API-KEY': `${getToken()}`}
        });
    },
    fetchStatistics(page: number) {
        return instance.get<any>(`get_current_month/`, {
            params: {
                page: page
            },
            headers: {'X-API-KEY': `${getToken()}`}
        });
    },
}


export const authApi = {
    login(data: LoginParamsType) {
        return instance.post<ResponseType, AxiosResponse<ResponseType>, any>('backoffice/auth/login', data)
    }
}

export type StatisticsParamsType = {
    bank?: string
    type?: string
    terminal?: string
    billingType?: string
    createdFrom?: string | Date
    createdTo?: string | Date
}