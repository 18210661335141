import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {AppThunk} from "./store";
import {personalAreaAPI} from "./axios.api";
import {appActions} from "./appReducer";
import {handleServerNetworkError} from "../utils/error-utils";
import {Bounce, toast} from "react-toastify";
import {removeToken, setToken, successToastify} from "../utils/utils";
import {useNavigate} from "react-router";

export type UserStateType = {
    name: string
    tg_id: string
    id: number
}
const initialState: UserStateType[] = [];

const slice = createSlice({
    name: "users",
    initialState,
    reducers: {
        removeUser: (state, action: PayloadAction<{ userId: number }>) => {
            // const tasks = state[action.payload.todolistId];
            const index = state.findIndex((u) => u.id === action.payload.userId);
            if (index !== -1) state.splice(index, 1);
        },
        addUser: (state, action: PayloadAction<{ user: UserStateType }>) => {
            state.push(action.payload.user);
        },
        setUsersWallets: (state, action: PayloadAction<{ users: Array<UserStateType> }>) => {
            return action.payload.users;
        },
    },

});

export const usersReducers = slice.reducer;
export const tasksActions = slice.actions;

// thunks

export const fetchUsersTC = (): AppThunk => {
    return (dispatch) => {
        dispatch(appActions.setAppStatus({status: "loading"}));
        personalAreaAPI.getUsers().then((res) => {
            dispatch(tasksActions.setUsersWallets({users: res.data}));
            dispatch(appActions.setAppStatus({status: "succeeded"}));
        }).catch((error) => {
            console.log(error)
            handleServerNetworkError(error, dispatch);
        });

    }
}
export type AddUserDataType = {
    name: string
    tg_id: string
}
export const addUserTC = (data: AddUserDataType): AppThunk => {
    return (dispatch) => {
        dispatch(appActions.setAppStatus({status: "loading"}));
        personalAreaAPI.addUser(data).then((res) => {
            dispatch(tasksActions.addUser({user: res.data}));
            dispatch(appActions.setAppStatus({status: "succeeded"}));
            successToastify()
        }).catch((error) => {
            handleServerNetworkError(error, dispatch);
            toast.error(error.data.message, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
                transition: Bounce,
            });
        });

    }
}


export const removeUserTC =
    (userId: number): AppThunk =>
        (dispatch) => {
            personalAreaAPI.deleteUser(userId).then(() => {
                dispatch(tasksActions.removeUser({userId}));
                successToastify()
            }).catch((error) => {
                handleServerNetworkError(error, dispatch);
            });
        };
