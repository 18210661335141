import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {AppThunk} from "./store";
import {personalAreaAPI} from "./axios.api";
import {appActions} from "./appReducer";
import {handleServerNetworkError} from "../utils/error-utils";
import {Bounce, toast} from "react-toastify";
import { successToastify} from "../utils/utils";
import {fetchMonitorWalletsTC} from "./monitorWalletsReducer";

export type ScreenStateType = {
    name: string
    tg_id: string
    id: number
}
const initialState: ScreenStateType[] = [];

const slice = createSlice({
    name: "screen",
    initialState,
    reducers: {
        removeScreen: (state, action: PayloadAction<{ screenId: number }>) => {
            const index = state.findIndex((u) => u.id === action.payload.screenId);
            if (index !== -1) state.splice(index, 1);
        },
        addScreen: (state, action: PayloadAction<{ screen: ScreenStateType }>) => {
            state.push(action.payload.screen);
        },
        setscreenWallets: (state, action: PayloadAction<{ screens: Array<ScreenStateType> }>) => {
            return action.payload.screens;
        },
    },

});

export const screensReducers = slice.reducer;
export const screenActions = slice.actions;

// thunks

export const fetchScreenTC = (): AppThunk => {
    return (dispatch) => {
        dispatch(appActions.setAppStatus({status: "loading"}));
        personalAreaAPI.getScreenBots().then((res) => {
            dispatch(screenActions.setscreenWallets({screens: res.data}));
            dispatch(appActions.setAppStatus({status: "succeeded"}));
        }).catch((error) => {
            console.log(error)
            handleServerNetworkError(error, dispatch);
        });

    }
}
export type AddScreenDataType = {
    name: string
    tg_id: string
}



export const addScreenTC = (data: AddScreenDataType): AppThunk => {
    return (dispatch) => {
        dispatch(appActions.setAppStatus({status: "loading"}));
        personalAreaAPI.addScreenBots(data).then((res) => {
            dispatch(screenActions.addScreen({screen: res.data}));
            dispatch(appActions.setAppStatus({status: "succeeded"}));
            successToastify()
        }).catch((error) => {
            handleServerNetworkError(error, dispatch);
            toast.error(error.data.message, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
                transition: Bounce,
            });
        });

    }
}


export const removeScreenTC =
    (screenId: number): AppThunk =>
        (dispatch) => {
            personalAreaAPI.deleteScreenBots(screenId).then(() => {
                dispatch(screenActions.removeScreen({screenId}));
                successToastify()
            }).catch((error) => {
                handleServerNetworkError(error, dispatch);
            });
        };
