import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {AppThunk} from "./store";
import {personalAreaAPI} from "./axios.api";
import {appActions} from "./appReducer";
import {handleServerNetworkError} from "../utils/error-utils";
import {successToastify} from "../utils/utils";

export type AutoLimitStateType = {
    chain: string
    coin: string
    min_amount: string
    id?: number
}
const initialState: AutoLimitStateType[] = [];

const slice = createSlice({
    name: "conclusion",
    initialState,
    reducers: {
        getAutoLimits: (state, action: PayloadAction<{ autoLimit: Array<AutoLimitStateType> }>) => {
            return action.payload.autoLimit;
        },
        setAutoLimit: (state, action: PayloadAction<{ gas: AutoLimitStateType, id: number }>) => {
            const index = state.findIndex((t) => t.id === action.payload.id);
            if (index !== -1) {
                state[index] = {...state[index], ...action.payload.gas};
            }
        },
    }

});

export const autoLimitReducers = slice.reducer;
export const autoLimitActions = slice.actions;

// thunks

export const fetchAutoLimitTC = (): AppThunk => {
    return (dispatch) => {
        dispatch(appActions.setAppStatus({status: "loading"}));
        personalAreaAPI.getAutoLimits().then((res) => {
            dispatch(autoLimitActions.getAutoLimits({autoLimit: res.data}));
            dispatch(appActions.setAppStatus({status: "succeeded"}));
        }).catch((error) => {
            handleServerNetworkError(error, dispatch);
        });

    }
}
export const setAutoLimitTC = (gas: AutoLimitStateType, id: number): AppThunk => {
    return (dispatch) => {
        dispatch(appActions.setAppStatus({status: "loading"}));
        personalAreaAPI.setAutoLimit(gas).then((res) => {
            dispatch(autoLimitActions.setAutoLimit({gas, id}));
            dispatch(appActions.setAppStatus({status: "succeeded"}));
            successToastify()
        }).catch((error) => {
            handleServerNetworkError(error, dispatch);
        });

    }
}

